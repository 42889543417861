import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Fab from "@mui/material/Fab";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const pages = ["/home", "/services", "/about_us", "/contact", "/blog"];

function NavigationBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event, path) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#041935" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <DoneAllIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={(e) => {
              e.preventDefault();
              navigate("/home");
            }}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "sans-serif",
              fontWeight: 560,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            AUTOMATION AGENTS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={(e) => {
                    navigate(page);
                  }}
                  sx={{
                    ":hover": {
                      bgcolor: "#fb73b5",
                      color: "white",
                    },
                  }}
                >
                  <Typography textAlign="center">
                    {page.substring(1).toLocaleUpperCase().replace("_", " ")}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <DoneAllIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 0,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "sans-serif",
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            AUTOMATION AGENTS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={(e) => {
                  navigate(page);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.substring(1).replace("_", " ")}
              </Button>
            ))}
          </Box>
          <Box className="hideOnMobile">
            <Tooltip title="Contact Us">
              <Fab
                variant="extended"
                onClick={() => {
                  navigate("/contact");
                }}
                sx={{
                  color: "white",
                  backgroundColor: "#fc3d76",
                  ":hover": {
                    bgcolor: "#fb73b5",
                    color: "white",
                  },
                  boxShadow: "0 10px 20px -10px #fc3d76",
                }}
              >
                <MailOutlineOutlinedIcon sx={{ mr: 1 }} />
                Contact Us
              </Fab>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavigationBar;
