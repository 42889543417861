import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomePage from "./components/homePage";
import BlogPage from "./components/blogPage";
import ContactPage from "./components/contactPage";
import ErrorPage from "./components/errorPage";
import SuccessPage from "./components/successPage";
import AboutUsPage from "./components/aboutUsPage";
import ServicesPage from "./components/servicesPage";
import "./App.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage></HomePage>,
  },
  {
    path: "/home",
    element: <HomePage></HomePage>,
  },
  {
    path: "/blog",
    element: <BlogPage></BlogPage>,
  },
  {
    path: "/contact",
    element: <ContactPage></ContactPage>,
  },
  {
    path: "/error",
    element: <ErrorPage></ErrorPage>,
  },
  {
    path: "/submitted",
    element: <SuccessPage></SuccessPage>,
  },
  {
    path: "/about_us",
    element: <AboutUsPage></AboutUsPage>,
  },
  {
    path: "/services",
    element: <ServicesPage></ServicesPage>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <RouterProvider router={router}></RouterProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
