import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar></NavigationBar>

      <Box
        noValidate
        autoComplete="off"
        sx={{
          background: "#041935",
          minHeight: "100vh",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ margin: "1%" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {" "}
          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid xs={12}>
              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "30px",
                  color: "white",
                  marginBottom: "0px",
                }}
              >
                We ran into an error :( Please try again
              </h1>
            </Grid>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Fab
              variant="extended"
              onClick={() => {
                navigate("/contact");
              }}
              sx={{
                color: "white",
                backgroundColor: "#fc3d76",
                ":hover": {
                  bgcolor: "#fb73b5",
                  color: "white",
                },
              }}
            >
              Try again
            </Fab>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default ErrorPage;
