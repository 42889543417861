import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";

function SuccessPage() {
  return (
    <>
      <NavigationBar></NavigationBar>

      <Box
        noValidate
        autoComplete="off"
        sx={{
          background: "#041935",
          minHeight: "100vh",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ margin: "1%" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {" "}
          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid xs={12}>
              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "30px",
                  color: "#5cb85c",
                  marginBottom: "0px",
                }}
              >
                We have received your request! We will reach out as soon as
                possible!
              </h1>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default SuccessPage;
