import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import productivity from "../files/Screenshot_2023-08-06_010756-removebg.png";

function BlogPage() {
  return (
    <>
      <NavigationBar></NavigationBar>

      <Box
        sx={{
          flexGrow: 1,
          background: "#041935",
          minHeight: "100vh",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={3} sx={{ margin: "2%" }}>
          {["Coming Soon..."].map((card) => {
            return (
              <Grid item="true" md={4} key={card}>
                <Card sx={{ maxWidth: 400, backgroundColor: "dark-gray" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={productivity}
                      alt="blog"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {card}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Coming soon...
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      Share
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export default BlogPage;
