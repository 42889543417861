import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import NavigationBar from "./navigationBar";
import Grid from "@mui/material/Unstable_Grid2";
import Fab from "@mui/material/Fab";
import { collection, addDoc } from "firebase/firestore";
import { useState } from "react";
import { firestore } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";

function ContactPage() {
  const [fullName, setFullName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  // const [fullNameError, setFullNameError] = useState(false);
  // const [organizationError, setOrganizationError] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [phoneError, setPhoneError] = useState(false);
  // const [descriptionError, setDescriptionError] = useState(false);
  var [error, setErrorMessage] = useState("");
  var fullNameError = false;
  var organizationError = false;
  var emailError = false;
  // var phoneError = false;
  var descriptionError = false;

  const navigate = useNavigate();

  const validateForm = (payload) => {
    if (
      payload.fullName == "" ||
      payload.fullName == null ||
      payload.fullName == undefined
    ) {
      // await setFullNameError(true);
      fullNameError = true;
    } else {
      fullNameError = false;
    }
    if (
      payload.organization === "" ||
      payload.organization === null ||
      payload.organization === undefined
    ) {
      organizationError = true;
    } else {
      organizationError = false;
    }
    if (
      payload.email === "" ||
      payload.email === null ||
      payload.email === undefined
    ) {
      emailError = true;
    } else {
      emailError = false;
    }
    // if (
    //   payload.phone === "" ||
    //   payload.phone === null ||
    //   payload.phone === undefined
    // ) {
    //   phoneError = true;
    // } else {
    //   phoneError = false;
    // }
    if (
      payload.description === "" ||
      payload.description === null ||
      payload.description === undefined
    ) {
      descriptionError = true;
    } else {
      descriptionError = false;
    }
  };

  const submitLead = async (event) => {
    event.preventDefault();
    const payload = {
      fullName: fullName,
      organization: organization,
      email: email,
      phone: phone,
      description: description.substring(0, 1000),
      createdAt: new Date().toUTCString(),
    };

    validateForm(payload);

    if (fullNameError || organizationError || emailError || descriptionError) {
      setErrorMessage("Please fill out all form fields, thank you!");
    } else {
      const docRef = await addDoc(
        collection(firestore, "leads"),
        payload
      ).catch((error) => {
        navigate("/error");
      });
      console.log("Document written with ID: ", docRef.id);
      console.log(payload);
      navigate("/submitted");
    }
  };

  return (
    <>
      <NavigationBar></NavigationBar>
      <Box
        sx={{
          background: "#041935",
          minHeight: "100vh",
          maxWidth: "100%",
          overflowX: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          autoComplete="off"
          className="centerForm centerFormMobile"
          component="form"
          noValidate
          sx={{ maxWidth: "75%" }}
        >
          <h2
            style={{
              fontFamily: "sans-serif",
              color: "red",
              marginBottom: "0px",
            }}
          >
            {error}
          </h2>
          <h1
            style={{
              fontFamily: "sans-serif",
              fontSize: "30px",
              color: "white",
              marginBottom: "15px",
            }}
          >
            How can we help?
          </h1>
          <TextField
            autoComplete="off"
            required
            label="Full Name"
            helperText="Please enter your full name"
            type="text"
            sx={{ width: 1, pb: 2, fieldset: { borderColor: "white" } }}
            InputLabelProps={{
              sx: { color: "white", borderColor: "white" },
            }}
            value={fullName}
            color="info"
            onChange={(e) => setFullName(e.target.value)}
          />
          <TextField
            color="info"
            autoComplete="off"
            required
            label="Organization"
            helperText="Enter your organization's name"
            type="text"
            sx={{ width: 1, pb: 2, fieldset: { borderColor: "white" } }}
            InputLabelProps={{
              sx: { color: "white", borderColor: "white" },
            }}
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
          />
          <TextField
            color="info"
            autoComplete="off"
            required
            label="Email"
            helperText="Enter your email address"
            type="email"
            sx={{ width: 1, pb: 2, fieldset: { borderColor: "white" } }}
            InputLabelProps={{
              sx: { color: "white", borderColor: "white" },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            autoComplete="off"
            color="info"
            label="Phone (optional)"
            type="text"
            helperText="Enter your phone number (optional)"
            InputLabelProps={{
              sx: { color: "white", borderColor: "white" },
            }}
            sx={{ width: 1, pb: 2, fieldset: { borderColor: "white" } }}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            autoComplete="off"
            color="info"
            required
            label="How can we help you?"
            type="text"
            multiline
            sx={{ width: 1, pb: 2, fieldset: { borderColor: "white" } }}
            InputLabelProps={{
              sx: { color: "white", borderColor: "white" },
            }}
            rows={5}
            helperText="Please describe your goals and/or issues."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box>
            <Button
              variant="extended"
              onClick={(e) => {
                submitLead(e);
              }}
              type="submit"
              sx={{
                color: "white",
                backgroundColor: "#fc3d76",
                ":hover": {
                  bgcolor: "#fb73b5",
                  color: "white",
                },
                width: 1,
                borderRadius: "20px",
                boxShadow: "0 10px 20px -10px #fc3d76",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactPage;
