import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";
import logos from "../files/icons.png";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import Paper from "@mui/material/Paper";

function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar />

      <Box
        sx={{
          background: "#041935",
          minHeight: "100vh",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          <Grid xs={11} sm={11} md={7} lg={7} xl={7}>
            <Box
              sx={{
                fontSize: {
                  xs: "5vw",
                  sm: "5vw",
                  md: "2vw",
                  lg: "2vw",
                  xl: "2vw",
                },
              }}
            >
              <h1
                style={{
                  fontFamily: "sans-serif",
                  color: "white",
                  marginBottom: "0px",
                }}
                className="typedOut typedOutMobile"
              >
                <b style={{ color: "#fb73b5" }}>Streamline Your Business</b>
                <br />
                with
                <br />
                Effortless Automation
                <br />
              </h1>
            </Box>
            <Box
              sx={{
                fontSize: {
                  xs: "5vw",
                  sm: "5vw",
                  md: "2vw",
                  lg: "2vw",
                  xl: "2vw",
                },
              }}
            >
              <p
                style={{
                  fontFamily: "sans-serif",
                  color: "white",
                  padding: 0,
                }}
                className="typedOut typedOutMobile"
              >
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.75rem",
                    color: "white",
                    paddingBottom: 10,
                    paddingTop: 30,
                    display: "block",
                  }}
                >
                  <br />
                  Automation Agents Empowers You To...
                  <br />
                  <br />
                  <strong style={{ color: "#fb73b5" }}>Optimize</strong> your
                  business.
                </span>
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.75rem",
                    color: "white",
                    paddingBottom: 10,
                    display: "block",
                  }}
                >
                  <strong style={{ color: "#fb73b5" }}>Simplify</strong> your
                  workflows.
                </span>
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.75rem",
                    color: "white",
                    paddingBottom: 10,
                    display: "block",
                  }}
                >
                  <strong style={{ color: "#fb73b5" }}>Automate</strong> your
                  tasks.
                </span>
              </p>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Fab
                className="buttonOnMobile"
                variant="extended"
                onClick={() => {
                  navigate("/contact");
                }}
                sx={{
                  color: "white",
                  backgroundColor: "#fc3d76",
                  ":hover": {
                    bgcolor: "#fb73b5",
                    color: "white",
                  },
                  boxShadow: "0 10px 20px -10px #fc3d76",
                }}
              >
                <CalendarMonthOutlinedIcon sx={{ mr: 1 }} />
                Schedule free consultation
              </Fab>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ p: 3 }}>
            <Divider></Divider>
          </Grid>
          <Grid xs={11} sm={11} md={3} lg={3} xl={3} sx={{ height: "100%" }}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#041945", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Optimize</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  By building the correct integrations between your existing
                  systems you can eliminate repetitive work, reduce errors,
                  accelerate processes and gain valuable insights into your
                  business needs.
                  <br />
                  <br />
                  Ultimately enhancing productivity, decision-making, and
                  fostering innovation.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={3} lg={3} xl={3} sx={{}}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#041945", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Simplify</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  By eliminating intermediate steps in you business processes
                  you can accelerates task completion, and see real-time
                  insights.
                  <br />
                  <br />
                  Leading to increased efficiency, minimized errors, and a more
                  streamlined and organized work environment.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={3} lg={3} xl={3}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#041945", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Automate</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  By leveraging our specialized automation consulting services,
                  you can eliminate tedius tasks.
                  <br />
                  <br />
                  Giving you the freedom to grow your business and provide the
                  best service to your clients.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={7} lg={7} xl={7}>
            <Box
              sx={{
                alignItems: "center",
                textAlign: "center",
                pt: 5,
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.25vw",
                  lg: "1.25vw",
                  xl: "1.25vw",
                },
              }}
            >
              <p
                style={{
                  fontFamily: "sans-serif",
                  color: "white",
                  marginBottom: "0px",
                  fontSize: "1.4rem",
                }}
              >
                <b style={{ color: "#fb73b5" }}>Our mission</b> is to reshape
                the way individuals and businesses interact with technology by
                harnessing the transformative power of AI chatbots and
                Automation.
                <br />
              </p>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={11} lg={11} xl={11}>
            <Box
              sx={{
                alignItems: "center",
                textAlign: "center",
                pt: 5,
              }}
            >
              <Fab
                className="buttonOnMobile"
                variant="extended"
                onClick={() => {
                  navigate("/services");
                }}
                sx={{
                  color: "white",
                  backgroundColor: "#fc3d76",
                  ":hover": {
                    bgcolor: "#fb73b5",
                    color: "white",
                  },
                  boxShadow: "0 10px 20px -10px #fc3d76",
                }}
              >
                <ContactsOutlinedIcon sx={{ mr: 1 }} />
                Learn more
              </Fab>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ p: 3 }}>
            <Divider></Divider>
          </Grid>
          <Grid xs={11} sm={11} md={7} lg={7} xl={7}>
            <Box
              sx={{
                backgroundColor: "inherit",
                alignItems: "center",
                textAlign: "center",
                xs: "3vw",
                sm: "3vw",
                md: "1.2vw",
                lg: "1.2vw",
                xl: "1.2vw",
              }}
            >
              <h1
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  color: "white",
                  fontSize: "2rem",
                }}
              >
                We Work With Your Favorite Tools!
              </h1>
              <img
                src={logos}
                style={{ width: "85%", height: "auto", maxWidth: "90vw" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.4rem",
                  color: "white",
                  paddingLeft: 7,
                  paddingRight: 7,
                }}
              >
                We work with a variety of tools to help{" "}
                <b style={{ color: "#fb73b5" }}>eliminate</b> repetitive tasks,{" "}
                <b style={{ color: "#fb73b5" }}>generate</b> more leads, and{" "}
                <b style={{ color: "#fb73b5" }}>improve</b> customer
                satisfaction, allowing you to focus on growing your business!
              </p>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ pb: 25 }}>
            <Divider></Divider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default HomePage;
