import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import aboutUsImage from "../files/ClipartKey_2799764.png";

function ServicesPage() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar />

      <Box
        sx={{
          background: "#041935",
          minHeight: "100vh",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
            <Box>
              <h1>
                <b style={{ color: "#fb73b5" }}>
                  Transform Your Business Through Smart Automation
                </b>
              </h1>
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.3rem",
                  color: "white",
                  marginBottom: "0px",
                }}
              >
                We specialize in creating tailored solutions that optimize your
                workflows, reduce manual effort, and drive remarkable results.
                <br />
                <br />
                What We Offer:
                <br />
              </p>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={10} lg={7} xl={7}>
            <Box>
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.2rem",
                  color: "white",
                  marginBottom: "0px",
                  paddingLeft: "2em",
                  display: "inline-block",
                }}
              >
                🚀 Robotic Process Automation (RPA): Automate repetitive tasks,
                data entry, and manual processes using cutting-edge RPA
                technology. Watch as your business gains the agility it needs to
                stay ahead in today's fast-paced market.
                <br />
                <br />
                🤝 Client-Centric Communication: Effective communication is
                paramount. We keep you in the loop throughout the project,
                explaining complex concepts in a way that makes sense to
                everyone.
                <br />
                <br />
                📊 Data-Driven Insights: Unlock hidden insights from your data
                with advanced data analysis and machine learning techniques.
                Make informed decisions that drive growth, reduce costs, and
                improve customer experiences.
                <br />
                <br />
                🚀 Custom AI Solutions: Leverage the latest advancements in
                artificial intelligence to design and implement solutions that
                are perfectly aligned with your business objectives.
                <br />
                <br />
                🚀 Proven Expertise: With a solid background in Software and
                Solutions Engineering, IT and Solutions Architecture, we bring a
                wealth of knowledge to every project. My portfolio showcases
                successful implementations across diverse industries.
                <br />
                <br />
                📊 Industry Insight: We understand the unique challenges
                businesses face and how Automation and AI can improve
                efficiency, allowing us to provide solutions that address
                specific pain points and capitalize on emerging opportunities.
                <br />
                <br />
                📈 Results-Driven Approach: Our focus is on delivering tangible
                results. We work closely with you to define clear objectives and
                ensure that the automation solutions we develop align with your
                strategic goals.
                <br />
                <br />
                🌐 Global Perspective: Operating in a digital world knows no
                boundaries. We collaborate with clients worldwide, bringing a
                global perspective to your local or international business.
                <br />
                <br />
              </p>
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.3rem",
                  color: "white",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Let's Transform Your Business Together! Contact us today to
                discuss your unique needs and start your journey towards
                increased efficiency, innovation, and success.
              </p>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={10} lg={10} xl={10}>
            <Box sx={{ alignItems: "center", textAlign: "center" }}>
              <Tooltip title="Contact Us">
                <Fab
                  variant="extended"
                  onClick={() => {
                    navigate("/contact");
                  }}
                  sx={{
                    color: "white",
                    backgroundColor: "#fc3d76",
                    ":hover": {
                      bgcolor: "#fb73b5",
                      color: "white",
                    },
                    boxShadow: "0 10px 20px -10px #fc3d76",
                  }}
                >
                  <MailOutlineOutlinedIcon sx={{ mr: 1 }} />
                  Contact Us
                </Fab>
              </Tooltip>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ pb: 25 }}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ServicesPage;
