import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import NavigationBar from "./navigationBar";
import logos from "../files/icons.png";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import aboutUsImage from "../files/ClipartKey_2799764.png";

function AboutUsPage() {
  const navigate = useNavigate();
  return (
    <>
      <NavigationBar />

      <Box
        sx={{
          background: "#041935",
          minHeight: "100vh",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
            <Box>
              <h1>
                <b style={{ color: "#fb73b5" }}>About AI Automation Agents:</b>
              </h1>
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.4rem",
                  color: "white",
                  marginBottom: "0px",
                }}
              >
                We started our journey 5 years ago working with a few of the
                tech giants, fintech and automation startups.
                <br />
                <br />
                We believe that in the rapidly evolving landscape of modern
                business, staying ahead requires not only adaptability but also
                the strategic integration of cutting-edge technologies.
                <br />
                <br />
                This is why we created AI Automation Agents to help your
                business optimize, simplify and automate your process. We use
                smart tools to create and personalize AI powered chatbot agents
                for you and your business' needs. So you can redirect your focus
                towards strategic decision-making, creative problem-solving, and
                value-driven initiatives.
                <br />
                <br />
              </p>
            </Box>
          </Grid>
          <Grid
            xs={11}
            sm={11}
            md={4}
            lg={4}
            xl={4}
            sx={{ alignItems: "center", textAlign: "center", margin: "auto" }}
            className="hideOnDesktop"
          >
            <h1
              style={{
                fontSize: "4rem",
              }}
            >
              <b style={{ color: "#fb73b5" }}>What Sets Us Apart</b>
            </h1>
          </Grid>
          <Grid xs={11} sm={11} md={4} lg={4} xl={4}></Grid>
          <Grid xs={11} sm={11} md={4} lg={4} xl={4}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#210445", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Expertise</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Our team comprises seasoned professionals with a wealth of
                  expertise in AI, automation, and technology.
                  <br />
                  <br />
                  We combine deep industry knowledge with a passion for
                  innovation to provide tailored solutions that address unique
                  business challenges.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={4} lg={4} xl={4}></Grid>
          <Grid xs={11} sm={11} md={4} lg={4} xl={4}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#0c4504", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Customization</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  We understand that every business is unique.
                  <br />
                  <br />
                  Our solutions are meticulously tailored to match the specific
                  needs and goals of each client, ensuring maximum impact and
                  value.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid
            xs={11}
            sm={11}
            md={4}
            lg={4}
            xl={4}
            sx={{ alignItems: "center", textAlign: "center", margin: "auto" }}
            className="hideOnMobile"
          >
            <h1
              style={{
                fontSize: "4rem",
              }}
            >
              <b style={{ color: "#fb73b5" }}>What Sets Us Apart</b>
            </h1>
          </Grid>
          <Grid xs={11} sm={11} md={4} lg={4} xl={4}>
            <Box
              sx={{
                fontSize: {
                  xs: "3vw",
                  sm: "3vw",
                  md: "1.1vw",
                  lg: "1.1vw",
                  xl: "1.1vw",
                },
                height: "100%",
                display: "flex",
              }}
            >
              <Paper
                variant="outlined"
                sx={{ backgroundColor: "#041945", p: 3 }}
              >
                <h2
                  style={{
                    fontFamily: "sans-serif",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  <b style={{ color: "#fb73b5" }}>Collaboration</b>
                </h2>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Collaboration is at the heart of our approach.
                  <br />
                  <br />
                  We work closely with our clients, fostering open communication
                  and partnership to ensure the seamless integration of AI
                  automation agents into their operations.
                </p>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={11} sm={11} md={5} lg={5} xl={5}>
            <Box
              sx={{
                backgroundColor: "inherit",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img
                src={aboutUsImage}
                style={{ width: "85%", height: "auto", maxWidth: "90vw" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.25rem",
                  color: "white",
                  paddingLeft: 7,
                  paddingRight: 7,
                }}
              ></p>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ pb: 25 }}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AboutUsPage;
